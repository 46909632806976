<template>
  <v-container fluid>

    <h3><v-icon @click="goBackToPreviousPage">mdi-chevron-left</v-icon>Charging Detail</h3>
    <br>
    <v-img class="image-detail" :src="imageDetail" />
    <br>
    <div v-if="data" class="text-detail">
      <span>{{ data.chargerModelName }}-{{ data.serialNumber }}-{{ data.qrCode }}</span>
    </div>
    <br>
    <v-card v-if="data">
      <v-card-text v-if="data.status != 'available'">
        <v-row>
          <v-col cols="6" class="text-detail-left">
            <span>Charging Power</span>
          </v-col>
          <v-col cols="6" class="text-detail-right">
            <span>{{ countChargingPower(dataStatus) }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="text-detail-left">
            <span>Energy Delivered</span>
          </v-col>
          <v-col cols="6" class="text-detail-right">
            <span>{{ countEnergyDelivered(dataStatus) }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="text-detail-left">
            <span>Duration</span>
          </v-col>
          <v-col cols="6" class="text-detail-right">
            <span>{{ countDuration(dataStatus) }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="text-detail-left">
            <span>SoC</span>
          </v-col>
          <v-col cols="6" class="text-detail-right">
            <span>{{ countSoC(dataStatus) }}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row>
          <v-col cols="6" class="text-detail-left">
            <span>Transaction ID</span>
          </v-col>
          <v-col cols="6" class="text-detail-right">
            <span>{{ dataStatus.currentTransactionID }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="text-detail-left">
            <span>Date</span>
          </v-col>
          <v-col cols="6" class="text-detail-right">
            <span>{{ getDateTransactionDate(dataStatus) }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="text-detail-left">
            <span>Time</span>
          </v-col>
          <v-col cols="6" class="text-detail-right">
            <span>{{ getTimeTransactionDate(dataStatus) }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="text-detail-left">
            <span>Duration</span>
          </v-col>
          <v-col cols="6" class="text-detail-right">
            <span>{{ countDuration(dataStatus) }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="text-detail-left">
            <span>Energy Delivered</span>
          </v-col>
          <v-col cols="6" class="text-detail-right">
            <span>{{ countEnergyDelivered(dataStatus) }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <loading v-else-if="initializeLoading"></loading>
    <div class="red--text text-center" v-else-if="initializeError">
      {{ initializeError }}
    </div>
    <div class="text-center" v-else>
      Charging Station or Connector not found
    </div>
    <v-row class="submit-btn-row">
      <v-col v-if="data && data.status !== 'available'">
        <v-btn @click="stopCharging" block class="text-none submit-btn" color="#E74B4D"><span class="submit-btn-span">Stop
            Charging</span></v-btn>
      </v-col>
      <v-col v-else-if="data && data.status === 'available'">
        <v-btn @click="dialog.isActive = true" block class="submit-btn" color="primary"  ><span class="submit-btn-span">Done</span></v-btn>
      </v-col>
    </v-row>
    <v-col cols="auto">
      <v-dialog transition="dialog-top-transition" width="100%" v-model="dialog.isActive">

        <template>
          <v-card>
            <v-toolbar color="primary" dark>
              <v-toolbar-title class="flex-grow-1">{{ dialog.title }}</v-toolbar-title>
              <v-btn icon @click="dialog.isActive = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="text-center">
              <img width="158" height="189" class="center-svg" :src="dialog.imgSvg" alt="SVG" />
              <div class="caption-size" >Charging Completed</div>
              <div class="caption-false" >
                <div class="caption-size-false">Please disconnect charger from your car.</div>
              </div>


            </v-card-text>
            <v-card-actions class="justify-end">
              <v-row justify="center" align="center" cols="12" sm="6" md="6">
                <v-col cols="6">
                  <v-btn block @click="dialog.isActive = false">No</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn block @click="goBackToPreviousPage" color="primary">Yes</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-container>
</template>

<script>
import Vue from "vue";
import chargingDetailRunning from '@/assets/images/svg/chargingDetailRunning.svg';
import chargingDetailDone from '@/assets/images/svg/chargingDetailDone.svg';
import loginChargerWarning from '@/assets/images/svg/loginChargerWarning.svg';
import Loading from "@/components/Loading.vue";
import coreApiClient from "@/services/apis/coreApiClient";
import { ChargingStation } from "@/utils/chargingStation";
import stateManager from "@common/services/stateManager";

export default Vue.extend({
  components: { Loading },
  data() {
    return {
      initializeLoading: false,
      initializeError: null,
      imageDetail: chargingDetailRunning,
      data: null,
      dataStatus: null,
      dialog: {
        isActive: false,
        imgSvg: loginChargerWarning,
        title: 'Notification'
      }
    };
  },
  methods: {
    getDateTransactionDate(dataStatus) {
      const dateTransaction = new Date(dataStatus.currentTransactionDate);
      const day = dateTransaction.getDate();
      const month = dateTransaction.getMonth() + 1;
      const year = dateTransaction.getFullYear();
      return `${day}/${month}/${year}`;
    },
    getTimeTransactionDate(dataStatus) {
      const dateTransaction = new Date(dataStatus.statusLastChangedOn);
      const dateNow = new Date();
      let hours = dateTransaction.getHours();
      let hoursNow = dateNow.getHours();
      const minutes = dateTransaction.getMinutes();
      const minutesNow = dateNow.getMinutes();
      if (hours >= 12) {
        hours -= 12;
      }
      if (hoursNow >= 12) {
        hoursNow -= 12;
      }
      const ampm = hours >= 12 ? 'pm' : 'am';
      const ampmNow = hoursNow >= 12 ? 'pm' : 'am';


      return `${hours}:${minutes} ${ampm} - ${hoursNow}:${minutesNow} ${ampmNow}`;
    },
    countChargingPower(dataStatus) {
      return (dataStatus.currentInstantWatts / 1000).toFixed(2) + ' kW';
    },
    countEnergyDelivered(dataStatus) {
      return (dataStatus.currentTotalConsumptionWh / 1000).toFixed(2) + ' kWh';
    },
    countDuration(dataStatus) {
      const durationInMs = Date.now() - new Date(dataStatus.statusLastChangedOn).getTime();
      const hours = Math.floor(durationInMs / 3600000);
      const minutes = Math.floor((durationInMs % 3600000) / 60000);

      if (hours === 0) {
        return `${minutes} minutes`;
      } else {
        return `${hours} hours, ${minutes} minutes`;
      }
    },
    countSoC(dataStatus) {
      return `${dataStatus.currentStateOfCharge}%`;
    },
    async getCurrentInfo(id, connectorId) {
      const objCharger = stateManager.get("objCharger");
      const keyChargingStations = id + ':' + connectorId;
      const charger = objCharger[keyChargingStations]

      const result = await coreApiClient.callRaw("chargingStations", "publicGetConnectorInfo", {
        id: charger.id,
        connectorId: charger.connectorId,
      }, null, false, keyChargingStations);
      return result
    },
    async initialize() {
      const id = this.$route.params.id;
      const connectorId = this.$route.params.connectorId;
      this.initializeLoading = true;

      const objCharger = stateManager.get("objCharger");
      const keyChargingStations = id + ':' + connectorId;
      if (objCharger && Object.keys(objCharger).indexOf(keyChargingStations) > -1) {
        const charger = objCharger[keyChargingStations]

        const result = await coreApiClient.callRaw("chargingStations", "publicGetConnectorInfo", {
          id: charger.id,
          connectorId: charger.connectorId,
        }, null, false, keyChargingStations);
        if (result && result.errorCode === 'NoError') {
          objCharger[keyChargingStations].status = ChargingStation.getStatus(result.status)
          this.dataStatus = result
          this.data = objCharger[keyChargingStations]
        }
      } else {
        console.log(false)
      }

      this.initializeLoading = false;
    },
    goBackToPreviousPage() {
      this.$router.push(`/app/my_charger/home_m`);
    },
    async stopCharging() {
      const keyState = this.data.id + ":" + this.data.connectorId
      const dataCurrent = await this.getCurrentInfo(this.data.id, this.data.connectorId);
      const result = await coreApiClient.call("chargingStations", "publicStopCharging", {
        id: this.data.id,
        connectorId: this.data.connectorId,
      }, null, false, keyState);
      if (result && result === true) {
        this.data.status = 'available'
        this.dataStatus = dataCurrent
        this.imageDetail = chargingDetailDone
      }
    }
  },
  async created() {
    await this.initialize();
  },
});
</script>
<style scoped>
.h3 {
  margin-left: 8px;
  /* Khoảng cách giữa biểu tượng mũi tên và tiêu đề */
  display: inline;
}

.icon-title {
  display: inline-flex;
  align-items: center;
  /* Căn chỉnh các phần tử theo chiều dọc */
}


.submit-btn-row {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  /* Khoảng cách từ lề dưới của màn hình */
  justify-content: space-around
}

.submit-btn {
  border: 1px solid;
  /* Thiết lập viền */
  border-radius: 4px;
  /* Định dạng các góc của nút */
}

.submit-btn-span {
  color: #fff !important;
}

.center-svg {
  display: block;
  /* Đảm bảo ảnh nằm trên một dòng */
  margin: 0 auto;
  /* Căn giữa theo chiều ngang */
}

.caption-size {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #333333;
}

.caption-false {

  text-align: left;
}

.caption-size-false {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;


  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 24px;
}

.image-detail {
  width: 153px;
  height: 151.05px;
  margin-left: auto;
  margin-right: auto;
}

.text-detail {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.text-detail-left {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.text-detail-right {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}</style>
